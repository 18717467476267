/* eslint-disable max-len */
import React, { useContext, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import HeroComponent from '../components/hero';
import IndustryDescription from '../components/industry-description';
import IndustryContext from '../components/industry-context';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SnakeLayout from '../components/snake-layout';
import { TantalumSupplyChainModel, SesameSupplyChainModel } from '../components/supply-chain-model';
import WhereIsUsedDiagram from '../components/where-is-used-diagram';
import RecsLayout from '../components/recs';
import {
  phoneIcon, laptopIcon, cameraIcon, cloudIcon, prostheticIcon, hearingAidIcon,
  EVIcon, glassesIcon, GPSIcon, asianCookingIcon, bakedGoodsIcon, hummusIcon,
  skinCreamIcon, soapIcon, skinCancerIcon, intravenousIcon,
} from '../components/where-is-used-diagram/icons';
import InteractiveMap from '../components/interactive-map';
import DownloadIcon from '../components/download-icon';
import DotIndicator from '../components/dot-indicator';
import getTextFunctionFromData, { textFunctionOptions, titleBodyPair } from '../utils/text';

import './main.scss';
import LocalLevelDiagram from '../components/local-level-diagram';
import CircularDiagram from '../components/circular-diagram';
import Footer from '../components/footer';
import DataMap from '../components/data-map';

interface IndexPageProps {
  data: {
    django: {
      allContents: titleBodyPair[]
    }
  }
}

const IndexPage = ({ data: { django: { allContents } } }: IndexPageProps) => {
  const getText = getTextFunctionFromData(allContents);
  const ref = useRef(null as HTMLDivElement);
  const anchorRef = useRef(null as HTMLDivElement);
  const diagramRef = useRef(null as HTMLDivElement);
  const { industry, setIndustry } = useContext(IndustryContext);

  const showSelector = () => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const resourceName = (industry === 'agriculture') ? 'Sesame' : 'Tantalum';

  useEffect(() => {
    if (document.location.hash === '#sesame') {
      setIndustry('agriculture');
      anchorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } else if (document.location.hash === '#diagram') {
      setIndustry('agriculture');
      diagramRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);
  const getPrefixText = (
    name: string, options?: textFunctionOptions,
  ) => getText(`${resourceName} ${name}`, options);
  return (
    <Layout>
      <SEO
        title="Child labour in the supply chain"
        description="How can we better address hidden child labour? A free, interactive tool to identify challenges and potential interventions at the origin of commodity supply chains helping move from a focus on finding and assessing the risks - to identifying effective interventions and minimising unintended consequences. The site draws together research that focuses on identifying pathways to remove children from the worst forms of child labour and to prevent it re-occurring - with recommendations for safe and decent alternatives."
      />
      <HeroComponent />
      <section data-section-title="Introduction">
        <DotIndicator />
        <article>
          <div className="intro-animation">
            <div className="video-player">
              <iframe
                src="https://player.vimeo.com/video/709169758?h=f57446c954"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Animation"
                className="video-player-wrapper"
              />
            </div>
          </div>
          <h2 className="heading-with-decoration hide-on-mobile">
            A geographical challenge
          </h2>
          <h3 className="beta hide-on-mobile">Global risk of child labour</h3>
          <div className="mobile-message">
            This site is only accessible on larger screens, please visit the
            full site on your laptop or large tablet to learn more.
          </div>
          <div className="introduction-box hide-on-mobile">
            <div>
              <DataMap />
            </div>
            <div>{getText('Child Labour Introduction Text')}</div>
          </div>
        </article>
        <h2 className="heading-with-decoration heading-with-decoration--tiny heading-with-decoration--alt hide-on-mobile">
          Two commodities with a high prevalence of the worst forms of child
          labour
        </h2>
      </section>
      <section
        className="section-red hide-on-mobile"
        data-section-title="Resource"
        ref={ref}
      >
        <DotIndicator />
        <div ref={anchorRef} />
        <IndustryDescription getText={getText} />
      </section>
      <section data-section-title="Industries" className="hide-on-mobile">
        <h2 className="heading-with-decoration heading-with-decoration--small">
          Where is
          {' '}
          {resourceName.toLowerCase()}
          {' '}
          used?
        </h2>
        <DotIndicator />
        <article>
          {resourceName === 'Tantalum' ? (
            <>
              <WhereIsUsedDiagram
                title={
                  getText('Tantalum Usage Electronics Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Tantalum Usage Phone Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage Phone Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: phoneIcon,
                  },
                  {
                    name: getText('Tantalum Usage Laptop Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage Laptop Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: laptopIcon,
                  },
                  {
                    name: getText('Tantalum Usage Camera Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage Camera Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: cameraIcon,
                  },
                  {
                    name: getText('Tantalum Usage IOT Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage IOT Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: cloudIcon,
                  },
                ]}
              >
                {getText('Tantalum Usage Electronics Industry Content')}
              </WhereIsUsedDiagram>
              <WhereIsUsedDiagram
                title={
                  getText('Tantalum Usage Medical Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Tantalum Usage Joint Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage Joint Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: prostheticIcon,
                  },
                  {
                    name: getText('Tantalum Usage Hearing Aid Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText(
                      'Tantalum Usage Hearing Aid Icon Title',
                      { stringOnly: true },
                    ) as string,
                    icon: hearingAidIcon,
                  },
                  {
                    name: getText('Tantalum Usage Glasses Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage Glasses Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: glassesIcon,
                  },
                ]}
              >
                {getText('Tantalum Usage Medical Industry Content')}
              </WhereIsUsedDiagram>
              <WhereIsUsedDiagram
                title={
                  getText('Tantalum Usage Automotive Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Tantalum Usage GPS Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Tantalum Usage GPS Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: GPSIcon,
                  },
                  {
                    name: getText(
                      'Tantalum Usage Electric Vehicle Icon Title',
                      { stringOnly: true },
                    ) as string,
                    description: getText(
                      'Tantalum Usage Electric Vehicle Icon Title',
                      { stringOnly: true },
                    ) as string,
                    icon: EVIcon,
                  },
                ]}
              >
                {getText('Tantalum Usage Automotive Industry Content')}
              </WhereIsUsedDiagram>
            </>
          ) : (
            <>
              <WhereIsUsedDiagram
                title={
                  getText('Sesame Usage Food Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Sesame Usage Asian Food Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Sesame Usage Asian Food Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: asianCookingIcon,
                  },
                  {
                    name: getText('Sesame Usage Baked Goods Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText(
                      'Sesame Usage Baked Goods Icon Title',
                      { stringOnly: true },
                    ) as string,
                    icon: bakedGoodsIcon,
                  },
                  {
                    name: getText('Sesame Usage Hummus Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Sesame Usage Hummus Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: hummusIcon,
                  },
                ]}
              >
                {getText('Sesame Usage Food Industry Content')}
              </WhereIsUsedDiagram>
              <WhereIsUsedDiagram
                title={
                  getText('Sesame Usage Cosmetics Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Sesame Usage Skin Cream Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Sesame Usage Skin Cream Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: skinCreamIcon,
                  },
                  {
                    name: getText('Sesame Usage Soap Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText('Sesame Usage Soap Icon Title', {
                      stringOnly: true,
                    }) as string,
                    icon: soapIcon,
                  },
                ]}
              >
                {getText('Sesame Usage Cosmetics Industry Content')}
              </WhereIsUsedDiagram>
              <WhereIsUsedDiagram
                title={
                  getText('Sesame Usage Pharmaceuticals Industry Title', {
                    stringOnly: true,
                  }) as string
                }
                icons={[
                  {
                    name: getText('Sesame Usage Cancer Treatment Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText(
                      'Sesame Usage Cancer Treatment Icon Title',
                      { stringOnly: true },
                    ) as string,
                    icon: skinCancerIcon,
                  },
                  {
                    name: getText('Sesame Usage Medical Carrier Icon Title', {
                      stringOnly: true,
                    }) as string,
                    description: getText(
                      'Sesame Usage Medical Carrier Icon Title',
                      { stringOnly: true },
                    ) as string,
                    icon: intravenousIcon,
                  },
                ]}
              >
                {getText('Sesame Usage Pharmaceuticals Industry Content')}
              </WhereIsUsedDiagram>
            </>
          )}
        </article>
      </section>
      <section
        className="section-brass hide-on-mobile"
        data-section-title="The supply chain"
      >
        <DotIndicator />
        <div className="brown-box">
          <div className="brown-box-inner">
            <SnakeLayout>
              <div>
                <h2 className="snake-layout-main-heading">
                  The
                  {' '}
                  {resourceName}
                  {' '}
                  supply chain
                </h2>
                {getPrefixText('Classic Supply Chain')}
              </div>
              <div>
                {resourceName === 'Tantalum' ? (
                  <TantalumSupplyChainModel />
                ) : (
                  <SesameSupplyChainModel />
                )}
              </div>
              {resourceName === 'Tantalum' ? (
                <div>{getPrefixText('New Supply Chain')}</div>
              ) : (
                ''
              )}
            </SnakeLayout>
            <InteractiveMap getText={getText} />
            <div className="download-area">
              <div className="download-widget">
                <div className="icon-box">
                  <a
                    href={`/pdf/${
                      industry === 'agriculture' ? 'Sesame' : 'Tantalum'
                    }_supply_chain_overview.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon />
                  </a>
                </div>
                <div className="text-under-icon">
                  <p>
                    <a
                      href={`/pdf/${
                        industry === 'agriculture' ? 'Sesame' : 'Tantalum'
                      }_supply_chain_overview.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download this content as a pdf guide here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        data-section-title="Resource at local level"
        className="hide-on-mobile"
        ref={diagramRef}
      >
        <DotIndicator />
        <h2 className="heading-with-decoration">
          {resourceName}
          {' '}
          at a local level
        </h2>
        <h3 className="beta">
          What child labour looks like in a
          {' '}
          {industry === 'mining' ? 'tantalum mining' : 'sesame farming'}
          {' '}
          community
        </h3>
        <LocalLevelDiagram getText={getText} />
      </section>
      <section data-section-title="Recommendations" className="hide-on-mobile">
        <DotIndicator />
        <RecsLayout>
          <div className="recs-section">
            <div className="main-box">
              <article>
                <h2 className="heading-with-decoration">Recommendations</h2>
                <div className="grid-container">
                  <div className="text-block">
                    {getPrefixText('Recommendations')}
                  </div>
                  <div className="graphics-block">
                    <div className="image-box">
                      <div className="circular-diagram-container">
                        <CircularDiagram />
                      </div>
                    </div>
                    <div>
                      <div className="icon-box">
                        <a
                          href={`/pdf/${
                            industry === 'agriculture' ? 'Sesame' : 'Tantalum'
                          } Recommendations.zip`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadIcon />
                        </a>
                      </div>
                      <div className="text-under-icon">
                        <p>
                          <a
                            href={`/pdf/${
                              industry === 'agriculture' ? 'Sesame' : 'Tantalum'
                            } Recommendations.zip`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download this content as a pdf guide here
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* eslint-disable-next-line */}
                    <div className="choose-different" onClick={showSelector}>
                      <div className="icon-box icon-box-reversed">
                        <DownloadIcon />
                      </div>
                      <div className="text-under-icon">
                        <p>Choose a different commodity</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </RecsLayout>
      </section>
      <Footer />
    </Layout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
query {
  django {
    allContents {
      title
      body
    }
  }
}
`;
